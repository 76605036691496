import React from "react";

const ArrowIndicator = ({ up = true }) => (
  <svg
    width="8"
    height="8"
    viewBox="0 0 8 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="4" cy="4" r="4" fill={up ? "#F7885B" : "#6FCF97"} />
    {up ? (
      <path
        d="M5.92317 4.2429L6.13727 4.00845C6.22792 3.90918 6.22829 3.74832 6.13808 3.64969L4.27365 1.58816C4.18345 1.48848 4.03723 1.48814 3.94754 1.58742L2.07374 3.63939C1.98308 3.73866 1.98272 3.89952 2.07292 3.99814L2.28594 4.23356C2.3771 4.33431 2.52621 4.33253 2.61591 4.23008L3.7222 2.95843L3.71528 5.99991C3.71496 6.14066 3.81763 6.25412 3.94557 6.25442L4.25341 6.25512C4.38135 6.25541 4.48454 6.14241 4.48486 6.00166L4.49178 2.96018L5.59323 4.23791C5.68245 4.34183 5.83156 4.34428 5.92317 4.2429Z"
        fill="white"
      />
    ) : (
      <path
        d="M2.28138 4.01601L2.06782 4.25094C1.97739 4.35042 1.97739 4.51128 2.06782 4.6097L3.93694 6.66698C4.02737 6.76646 4.17359 6.76646 4.26306 6.66698L6.13218 4.61076C6.22261 4.51128 6.22261 4.35042 6.13218 4.252L5.91862 4.01706C5.82723 3.91653 5.67813 3.91865 5.58866 4.0213L4.48527 5.29546L4.48527 2.25398C4.48527 2.11323 4.38234 1.99999 4.2544 1.99999H3.94656C3.81862 1.99999 3.71569 2.11323 3.71569 2.25398L3.71569 5.29546L2.61134 4.02024C2.52187 3.91653 2.37277 3.91441 2.28138 4.01601V4.01601Z"
        fill="white"
      />
    )}
  </svg>
);

export default ArrowIndicator;
