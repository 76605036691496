import React from "react";

const SignOut = () => (
  <svg
    width="25"
    height="19"
    viewBox="0 0 25 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.654 10.3405L16.3203 18.6516C15.5762 19.3937 14.2864 18.8742 14.2864 17.8106V13.0614H7.54006C6.8803 13.0614 6.34952 12.532 6.34952 11.8741V7.12487C6.34952 6.46691 6.8803 5.93757 7.54006 5.93757H14.2864V1.18835C14.2864 0.129677 15.5712 -0.394715 16.3203 0.347349L24.654 8.65847C25.1153 9.1235 25.1153 9.87545 24.654 10.3405ZM9.52428 18.4042V16.4254C9.52428 16.0989 9.25641 15.8318 8.92901 15.8318H4.76214C3.88412 15.8318 3.17476 15.1243 3.17476 14.2487V4.75026C3.17476 3.87463 3.88412 3.16719 4.76214 3.16719H8.92901C9.25641 3.16719 9.52428 2.90005 9.52428 2.57354V0.594704C9.52428 0.268195 9.25641 0.00105218 8.92901 0.00105218H4.76214C2.13304 0.00105218 0 2.1283 0 4.75026V14.2487C0 16.8706 2.13304 18.9979 4.76214 18.9979H8.92901C9.25641 18.9979 9.52428 18.7308 9.52428 18.4042Z"
      fill="white"
    />
  </svg>
);

export default SignOut;
