import React from "react";

const MenuSearch = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24 22.743L17.0779 15.8202C18.4694 14.1467 19.3071 11.9986 19.3071 9.65318C19.3071 4.32101 14.985 0 9.65356 0C4.32213 0 0 4.32196 0 9.65318C0 14.9844 4.32309 19.3064 9.65356 19.3064C12 19.3064 14.1482 18.4686 15.8208 17.0772L22.7429 24L24 22.743V22.743ZM1.01596 9.65318C1.01596 4.89092 4.89016 1.01592 9.65356 1.01592C14.416 1.01592 18.2912 4.89092 18.2912 9.65318C18.2912 14.4154 14.416 18.2904 9.65356 18.2904C4.89016 18.2904 1.01596 14.4154 1.01596 9.65318V9.65318Z"
      fill="white"
    />
  </svg>
);

export default MenuSearch;
