import React from "react";

const PieChart = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.9991 12C23.9991 5.535 18.8836 0.265586 12.479 0.012604V0H11.5192V0.012604C5.11546 0.264686 0 5.5341 0 12C0 18.628 5.37205 23.9991 11.9991 23.9991C14.3273 23.9991 16.4979 23.3338 18.339 22.1868L18.3453 22.1958L18.7027 21.9527C18.7072 21.95 18.7108 21.9473 18.7153 21.9446L19.1402 21.6565L19.1339 21.6475C22.086 19.4616 24 15.955 24 11.9991L23.9991 12ZM22.0995 7.54985L12.4799 11.2978V0.973216C16.7779 1.15868 20.4456 3.81184 22.0995 7.54985ZM12 23.0394C5.91312 23.0394 0.960612 18.0878 0.960612 12C0.960612 6.07427 5.65474 1.2253 11.5201 0.973216V12.1476L17.7979 21.3892C16.1116 22.4335 14.1256 23.0394 12 23.0394ZM18.591 20.8508L12.7346 12.2287L22.447 8.44384C22.8287 9.56021 23.0385 10.7558 23.0385 12C23.0385 15.6183 21.2883 18.8359 18.5901 20.8508H18.591Z"
      fill="black"
    />
  </svg>
);

export default PieChart;
