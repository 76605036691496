import React from "react";

const Home = () => (
  <svg
    width="29"
    height="29"
    viewBox="0 0 29 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.5 2.42151L9.66876 7.25273V5.3197H5.8036V11.1179L2.40063 14.5209L3.08395 15.2042L14.5 3.78813L25.916 15.2042L26.5993 14.5209L14.5 2.42151ZM6.76967 6.28666H8.70179V8.21879L6.76876 10.1518V6.28666H6.76967Z"
      fill="black"
    />
    <path
      d="M5.80365 14.983V26.5785H12.5679V19.8142H16.4331V26.5785H23.1973V14.983L14.5009 6.28665L5.80456 14.983H5.80365ZM22.2303 25.6124H17.3991V18.8482H11.6009V25.6124H6.76971V15.3836L14.5 7.65328L22.2303 15.3836V25.6124Z"
      fill="black"
    />
  </svg>
);

export default Home;
