import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "typeface-roboto";

let vh = Math.abs(window.visualViewport.height);
document.documentElement.style.setProperty("--vh", `${vh}px`);
window.addEventListener("resize", () => {
  document.documentElement.style.setProperty("--vh", `${vh}px`);
});
// Then we set the value in the --vh custom property to the root of the document

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
