import React from "react";

const Search = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 11.3715L8.53896 7.9101C9.23469 7.07334 9.65356 5.99929 9.65356 4.82659C9.65356 2.16051 7.49249 0 4.82678 0C2.16107 0 0 2.16098 0 4.82659C0 7.4922 2.16154 9.65318 4.82678 9.65318C6 9.65318 7.0741 9.23432 7.91041 8.53862L11.3715 12L12 11.3715V11.3715ZM0.507982 4.82659C0.507982 2.44546 2.44508 0.507962 4.82678 0.507962C7.20801 0.507962 9.14558 2.44546 9.14558 4.82659C9.14558 7.20772 7.20801 9.14522 4.82678 9.14522C2.44508 9.14522 0.507982 7.20772 0.507982 4.82659V4.82659Z"
      fill="#555555"
    />
  </svg>
);

export default Search;
